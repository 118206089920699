import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import PronosticsResultat from "./PronosticsResultat";
import TableauBacktest from "./TableauBacktest";
import TableauBacktestJeux from "./TableauBacktestJeux";
import TableauHistorique from "./TableauHistorique";
import TableauEcart from "./TableauEcart";
import TableauCombinaisonJeux from "./TableauCombinaisonJeux";
import ChartEcarts from "./ChartEcarts";
import ChartChevauxRetenu from "./ChartChevauxRetenu";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableauBacktestJeuxSuite from "./TableauBacktestJeuxSuite";
import TableauBacktestReussite from "./TableauBacktestReussite";
import TableauCotation from "./TableauCotation";
import TableauCotationZoom from "./TableauCotationZoom";
import TableauRentabilite from "./TableauRentabilite";
import TableauRentabilitePlace from "./TableauRentabilitePlace";

export default class DialogResultat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      open: this.props.open
    };
    
  }

  closeDialog = () => {
    this.props.closeDialog();
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
    this.setState({ open: props.open})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function restriction(data){
      
      switch(data){
        case 0 : return "Aucune, afficher tous les chevaux."
        case 1 : return "Seulement le 1er cheval."
        case 2 : return "Seulement le 2ème cheval."
        case 3 : return "Seulement le 3ème cheval."
        case 4 : return "Seulement le 4ème cheval."
        case 5 : return "Seulement le 5ème cheval."
        case 6 : return "Seulement le 6ème cheval."
        case 7 : return "Seulement le 7ème cheval."
        case 8 : return "Seulement le 8ème cheval."
      }

      return data;
    }

    function ordo(data){
      if(data == 0){
        return "Par numéro de partant croissant";
      }else{
        return "Par numéro de partant décroissant";
      }
    }

    function exclusion(data){
      
      switch(data){
        case 0 : return "Aucune"
        case 1 : return "Exclure les pronostics plus d'un cheval"
        case 2 : return "Exclure les pronostics plus deux chevaux"
        case 3 : return "Exclure les pronostics plus trois chevaux"
        case 4 : return "Exclure les pronostics plus quatre chevaux"
      }

      return data;
    }

    return(
      <Dialog
      fullWidth
      open={this.state.open}
      maxWidth={"lg"}
      onClose={this.closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div>
          {<span class="lato-bold" style={{fontSize:"18px"}}>{this.state.data != null ? this.state.data.methode.label : "Pronostics"}</span>}
        </div>

        <div>
        {<span class="lato-regular-italic" style={{fontSize:"15px"}}>{this.state.data != null ? this.state.data.methode.description : ""}</span>}
        </div>
       
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={this.closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
      <CloseIcon style={{color:"black"}} />
      </IconButton>
      <Divider/>
      <DialogContent>
      
      <div>
     

      <div style={{marginTop:"0.5em"}}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
        <span class="lato-bold" style={{fontSize:"14px"}}>Détails du pronostics</span>
        </AccordionSummary>
        <AccordionDetails>
        
          <span class="lato-bold" style={{fontSize:"14px"}}>{this.state.data != null && this.state.data.selection != null ? this.state.data.selection.label : ""}</span>
        
        <Table style={{marginTop:"0.5em"}} component={Paper}>
        {this.state.data != null && this.state.data.selection.description.split(";").map((item, index) => (
          <TableRow>
              <TableCell style={{width:"60%"}} align="left">
               <div style={{display:"block"}}>
                  <div>
                    <span class="lato-regular" style={{fontSize:"14px"}}>
                      {item.split(":")[0].split(',')[1]}
                    </span>
                  </div>
                  <div>
                    <span class="lato-regular-italic" style={{fontSize:"14px"}}>
                      {item.split(":")[0].split(',')[0]}
                    </span>
                  </div>
               </div>
                
              </TableCell>
              <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{item.split(":")[1]}</span></TableCell>
            </TableRow>
        ))}
        </Table>
        <div style={{marginTop:"1em"}}>
        
          <span class="lato-bold" style={{fontSize:"14px"}}>{this.state.data != null && this.state.data.conditionCourse ? this.state.data.conditionCourse.label : ""}</span>
        
        </div>
        <Table style={{marginTop:"1em"}} component={Paper}>
        {this.state.data != null && this.state.data.conditionCourse.description.split(";").map((item, index) => (
          <TableRow>
              <TableCell style={{width:"60%"}} align="left"><span class="lato-regular" style={{fontSize:"14px"}}>{item.split(":")[0]}</span></TableCell>
              <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{item.split(":")[1]}</span></TableCell>
            </TableRow>
        ))}
        </Table>
        <div style={{marginTop:"1em"}}>
        <span class="lato-bold" style={{fontSize:"14px"}}>Période d'analyse</span>
          <Table style={{marginTop:"1em"}} component={Paper}>
          <TableRow>
              <TableCell style={{width:"60%"}} align="left"><span class="lato-regular" style={{fontSize:"14px"}}>Période</span></TableCell>
              <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{this.state.data != null && this.state.data.methode.periode ? this.state.data.methode.periode.label : ""}</span></TableCell>
           </TableRow>
        </Table>
        </div>

        <div style={{marginTop:"1em"}}>
        <span class="lato-bold" style={{fontSize:"14px"}}>Options sur les pronostics</span>
          <Table style={{marginTop:"1em"}} component={Paper}>
          <TableRow>
              <TableCell style={{width:"60%"}} align="left"><span class="lato-regular" style={{fontSize:"14px"}}>Ordonnancement</span></TableCell>
              <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{this.state.data != null && ordo(this.state.data.methode.tri)}</span></TableCell>
           </TableRow>
          <TableRow></TableRow>
          <TableRow>
              <TableCell style={{width:"60%"}} align="left"><span class="lato-regular" style={{fontSize:"14px"}}>Exclusion Pronostics</span></TableCell>
              <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{this.state.data != null && exclusion(this.state.data.methode.exclusion)}</span></TableCell>
           </TableRow>
          <TableRow>
              <TableCell style={{width:"60%"}} align="left"><span class="lato-regular" style={{fontSize:"14px"}}>Restriction Pronostics</span></TableCell>
              <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{this.state.data != null && restriction(this.state.data.methode.restriction)}</span></TableCell>
           </TableRow>
        </Table>
        </div>
       
        </AccordionDetails>
      </Accordion>
      </div>

      

        <div style={{marginBottom:"2em", marginTop:"1em"}}>
        <center>
          <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>analyse</span>
          <div style={{marginTop:"1em", width:"70%"}}>
            <Divider/>
          </div>
        </center>
        </div>
        {this.state.data != null &&  (
          <div>
          <div style={{display:"flex"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauBacktest data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauBacktestJeux data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauBacktestJeuxSuite data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
            <TableauBacktestReussite data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauEcart data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauCombinaisonJeux data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauCotation data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
            <TableauCotationZoom data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauRentabilite data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauRentabilitePlace data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
            <ChartEcarts data={this.state.data.analyse.histoEcarts} />
            </div>
            <div style={{width:"50vw"}}>
            <ChartChevauxRetenu data={this.state.data.analyse.histoChevauxRetenu} />
            </div>
            
          </div>
          
          
          {this.state.data.analyse.jour.length > 0 &&  (
          <div style={{marginTop:"1em"}}>
          <Card>
          <CardHeader
          
          title={<span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Aujourd'hui</span>}
          
         />
         {this.state.data.methode.warning &&  (
             <Alert style={{margin:"1em"}} severity="info">
              <span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>{this.state.data.methode.messageWarning}</span>
             </Alert>
          )}
            <TableauHistorique data={this.state.data.analyse.jour}/>
          </Card>
          </div>
          )}

         

          {this.state.data.analyse.jour.length == 0 &&  (
            <div style={{marginTop:"1em"}}>
              <Alert severity="info">
              <span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Aucun partant n'a été détecté pour les courses du jour.</span>
              </Alert>
            </div>
          )}

          

          
          
          <div style={{marginTop:"1em"}}>
          <Card>
          <CardHeader
          
          title={<span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Permanence des jeux</span>}
          
         />
            <TableauHistorique data={this.state.data.analyse.historiques}/>
          </Card>
          </div>
        </div>
        )}
        
      </div>
        
      </DialogContent>
      <Divider/>
      <DialogActions>

      </DialogActions>
    </Dialog>
    )
  }
}