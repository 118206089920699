import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogAide from "./DialogAide";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauRentabilitePlace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      aide: false
    };
    
  }

  openAide = () => {
    this.setState({ aide: true})
  }

  closeAide = () => {
    this.setState({ aide: false})
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function rde(data){
      
      if(data >= 0.90 && data < 1){
        return (
          <div style={{display:"flex"}}>
            <div>
          {((data - 1) * 100).toFixed(1)} % 
          </div>
          <div style={{marginLeft:"1em", marginTop:"-0.2em"}}>
          <SentimentDissatisfiedOutlinedIcon color="warning" />
          </div>
          </div>
        )
      }else if(data < 0.90){
        return (
          <div style={{display:"flex"}}>
            <div>
            {((data - 1) * 100).toFixed(1)} %
          </div>
          <div style={{marginLeft:"1em", marginTop:"-0.2em"}}>
          <SentimentDissatisfiedOutlinedIcon color="error" />
          </div>
          </div>
        )
      }
      
      else if(data >= 1){
        return (
          <div style={{display:"flex"}}>
            <div>
            {((data - 1) * 100).toFixed(1)} %
          </div>
          <div style={{marginLeft:"1em", marginTop:"-0.2em"}}>
          <SentimentSatisfiedAltOutlinedIcon color="success" />
          </div>
          </div>
        )
      }
    }

    return(
      <div >
  {this.state.aide && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAide}
    content={<span class="lato-regular">Ce tableau présente la rentabilité moyenne, calculée à partir d'un référentiel comprenant l'ensemble des rapports PMU. Il permet d'évaluer la performance financière des sélections.</span>}
    />
    )}

      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">Rendement Simple Placé</span></TableCell>
            <TableCell>
            <IconButton aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.openAide} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Rentabilité moyenne :</span></TableCell>
              <TableCell><span class="lato-regular">{rde(this.state.data.analyse.rendementPlace)}</span></TableCell>
            </TableRow>
            
        </TableBody>
      </Table>
      </div>
    )
  }
}