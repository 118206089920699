import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogAide from "./DialogAide";


import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauCotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      aide:false
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  openAide = () => {
    this.setState({ aide: true})
  }

  closeAide = () => {
    this.setState({ aide: false})
  }


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div >
        {this.state.aide && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAide}
    content={<span class="lato-regular">Ce tableau fournit une analyse des cotes des chevaux sélectionnés, gagnants et placés, en indiquant les valeurs minimales, maximales et moyennes. Il offre un aperçu des niveaux de risque et de performance associés.</span>}
    />
    )}

      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">Informations sur les cotations</span></TableCell>
            <TableCell>
            <IconButton aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.openAide} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Cotes des chevaux sélectionnés (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.minCotation} / {this.state.data.analyse.maxCotation} / {this.state.data.analyse.moyCotation}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Cotes des chevaux gagnants (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.minCotationGagnant} / {this.state.data.analyse.maxCotationGagnant} / {this.state.data.analyse.moyCotationGagnant}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Cotes des chevaux placés (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.minCotationPlace} / {this.state.data.analyse.maxCotationPlace} / {this.state.data.analyse.moyCotationPlace}</span></TableCell>
            </TableRow>
        </TableBody>
      </Table>
      </div>
    )
  }
}