import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CardHeader from '@mui/material/CardHeader';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TableauPronostics from "./TableauPronostics";
import FiltrePronostics from "./FiltrePronostics";
import DialogResultat from "./DialogResultat";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Tooltip from '@mui/material/Tooltip';
import SyntheseResultat from './SyntheseResultat';
import SyntheseResultatPoints from './SyntheseResultatPoints';
import FiltreSynthese from './FiltreSynthese';
import DialogAide from "./DialogAide";
import DialogOnboard from "./DialogOnboard";
import DialogAuth from "./DialogAuth";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyIcon from '@mui/icons-material/Key';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import AlertTitle from '@mui/material/AlertTitle';
import DialogFicheCourse from "./DialogFicheCourse";
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';

export default class Synthese extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loader: false,
          synthese: null,
          courseSelected: null,
          connect: false,
          openAuth: false,
          openUser: false,
          utilisateurConnect: null,
          zoom: false,
          taillePanelFiltre: "30vw",
          taillePanel: "70vw"        }
    }

    componentWillReceiveProps(props) {        
    }

    componentDidMount() {

      fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        this.auth("SYNTHESE_" + data.ip);
      })
      .catch(error => console.error(error));
    }

    auth = (ipClient) => {
      
      let user =  localStorage.getItem("utilisateur_dtf");
      let pass = localStorage.getItem("pass_dtf");

      fetch(
        Const.URL_WS_AUTH + `?email=${user}&password=${pass}&ipClient=${ipClient}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
         
          if(!data.register){
            this.setState({connect: false});
            if(localStorage.getItem("onboard") == 1){
              this.setState({openAuth: true});
            }
          }else{
            this.setState({connect: true});
            this.setState({utilisateurConnect: data});
          }
          
        })
        .catch((error) => {
          console.log("Request failed", error);
          localStorage.removeItem("utilisateur_dtf");
          localStorage.removeItem("pass_dtf");
          this.setState({loader: false});
        });
    }

    handleCourseSelect = (course, dossier) => {
      this.callSynthese(course.id, dossier);
    }

    activeAide =() => {
      localStorage.setItem("onboard", 0);
      window.location.href = Const.URL + "synthese";
    }

    selectedCourse = (courseSelected, dossier) => {
      if(courseSelected != null){
        this.callSynthese(courseSelected, dossier)
      }else{
        this.setState({courseSelected: null});
        this.setState({synthese: null});
      }
      
    }

    callSynthese = (courseSelected, dossier) => {
   
      this.setState({loader: true});
      
      fetch(
        Const.URL_WS_SYNTHESE + `?key=${courseSelected.keyC}&dossier=${dossier}&utilisateur=${localStorage.getItem("utilisateur_dtf")}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {

          this.setState({ synthese:data }, () => {
            this.setState({loader: false});
            this.setState({courseSelected: courseSelected});
          });
          
          
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    deco = () => {
      localStorage.removeItem("utilisateur_dtf");
      localStorage.removeItem("pass_dtf");
      window.location.href = Const.URL;
    }

    openUser = () => {
      this.setState({openUser: true});
    }

    closeUser = () => {
      this.setState({openUser: false});
    }
    
    redirectPronostics = () => {
      window.location.href = Const.URL + "pronostics";
    }

    closeAuth = () => {
      this.setState({openAuth: false});

      window.location.href = Const.URL;
    }

    closeAuthEtRedirection = () => {
      window.location.href = "https://dataturf.fr/";
    }

    fermerOnboard = () => {
      if(!this.state.connect){
        this.setState({openAuth: true});
      }
    }

    resize = () => {
      if(this.state.zoom){
        this.setState({zoom: false});
        this.setState({taillePanelFiltre: '30vw'});
        this.setState({taillePanel: '70vw'});
      }else{
        this.setState({taillePanelFiltre: '0vw'});
        this.setState({taillePanel: '100vw'});
        this.setState({zoom: true});
      }
    }
   
    render() {
        return (
            <div>
            
              {(localStorage.getItem("onboard") == null || localStorage.getItem("onboard") == 0) && (
                <DialogOnboard titre={"Trotstats ePro"} open={true} cookie={"onboard"} fermer={this.fermerOnboard}/>
              )}

               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomColor:"grey", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.3em"}}>- Édition ePro</span> 
                  </div>
                </div>
                </Grid>
                <Grid item xs={6}>
                  
                  <div style={{float:"right", margin:"0.5em"}}>

                  <Tooltip title={<span class="lato-regular">Aide</span>}>
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.activeAide}
                  >
                  <HelpOutlineOutlinedIcon style={{color:"black"}} />
                  </IconButton>
                  </Tooltip>

                  <Tooltip title={<span class="lato-regular">Gestion des pronostics</span>}>
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectPronostics}
                  >
                   {(this.state.connect && (
                      <TuneOutlinedIcon style={{color:"black"}} />
                     ))}
                     {(!this.state.connect && (
                      <LockOutlinedIcon style={{color:"black"}} />
                     ))}
                  
                  
                  </IconButton>
                  </Tooltip>
                   
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.openUser}
                  >
                     
                      <FaceOutlinedIcon style={{color:"black"}} />
                     
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: this.state.taillePanelFiltre, maxWidth: this.state.taillePanelFiltre, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA' , overflow:'scroll', overflowX:'hidden' }}>
              <div style={{margin:"1em"}}> 
                <FiltreSynthese selectedCourse={this.selectedCourse}/>
              </div>
            </Box>

            <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA', overflow:'scroll', overflowX:'hidden' }}>
                
                <div style={{margin:"1em"}}>
        <div style={{marginTop:"1em", marginBottom:"2em"}}>
        <center>
          <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>synthèse des pronostics</span>
          <div style={{float:"right"}}>
          <IconButton onClick={() => this.resize()} aria-label="delete">
            {this.state.zoom && (
               <FullscreenExitOutlinedIcon style={{color:"black"}} />
            )}
            {!this.state.zoom && (
              <FullscreenOutlinedIcon style={{color:"black"}}/> 
            )}
          </IconButton>
          </div>
          <div style={{marginTop:"1em", width:"70%"}}>
            <Divider/>
          </div>
        </center>
        </div>

        {this.state.synthese != null && this.state.courseSelected != null && (
          <SyntheseResultat synthese={this.state.synthese} course={this.state.courseSelected}/>
        )}

          {this.state.synthese == null && (
                 <div class="loadingImage">
                  <center>
                 <img width={"50%"} height={"50%"} style={{opacity:0.1}} src="https://dataturf.fr/images/brain.jpg" alt="Brain Image"/>
                 </center>
                  </div>
          )}

        
        </div>

                </Box>
            </div>
                
            </Box>

            </div>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
   

            <Dialog
              open={this.state.openAuth}
              onClose={() => this.closeAuth()}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                  <center>
                    <span class="lato-bold" style={{ textTransform:"none", fontSize:"16px"}}>Trotstats ePro - Version 4</span>
                  </center>
              </DialogTitle>
              
              <Divider/>
              <DialogContent>
                
              <Grid container spacing={2}>
                        <Grid item xs={7}>
                            
                            <Grid item xs={12}>
                                <FormControl  style={{marginTop:"25px"}} fullWidth>
                                    <OutlinedInput
                                        autoComplete="false"
                                        style={{fontVariantCaps:"small-caps", fontSize:"14px"}}
                                        id="inputUtilisateur"
                                        placeholder="utilisateur"
                                        margin="dense"
                                        size="small"
                                        startAdornment={<InputAdornment position="start"><AccountCircle/></InputAdornment>}
                                        label=""
                                        onChange={(event) => localStorage.setItem('utilisateur_dtf', event.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl style={{marginTop:"25px"}} fullWidth>
                                    <OutlinedInput
                                        autoComplete="false"
                                        style={{fontVariantCaps:"small-caps", fontSize:"14px"}}
                                        id="inputPassword"
                                        placeholder="mot de passe"
                                        type="password"
                                        size="small"
                                        margin="dense"
                                        startAdornment={<InputAdornment position="start"><KeyIcon/></InputAdornment>}
                                        label=""
                                        onChange={(event) => localStorage.setItem('pass_dtf', event.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                style={{marginTop:"25px"}}
                                    fullWidth 
                                    variant="outlined"
                                    onClick={() => this.closeAuth()}
                                    startIcon={<ArrowRightIcon />}>
                                    <span class="lato-regular" style={{ textTransform:"none", fontSize:"15px"}}>Accèdez à Trotstats</span>
                                </Button>

                                <Button 
                                style={{marginTop:"25px"}}
                                    fullWidth 
                                    variant="outlined"
                                    onClick={() => this.closeAuthEtRedirection()}
                                    startIcon={<KeyIcon />}>
                                    <span class="lato-regular" style={{ textTransform:"none", fontSize:"15px"}}>Je n'ai pas de compte</span>
                                </Button>

                            </Grid>

                           

                            <Grid item xs={12}>
                                <div style={{marginTop:"15px"}}>
                                <center>
                                <span class="lato-regular" style={{ fontSize:"14px"}}>Pour plus d'informations, vous pouvez nous contacter par e-mail: <b>information@dataturf.fr</b></span>
                                </center>
                                </div>
                            </Grid>

                            </Grid>
                            <Grid item xs={5}>
                                <div style={{marginTop:"1em"}}>
                                <center>
                                <img width="220px" height="220px" style={{opacity:0.3}} src="https://dataturf.fr/images/brain.jpg" />
                                </center>
                                </div>
                            </Grid>


                        </Grid>

                
              </DialogContent>
              <Divider/>
              <DialogActions>
                <div style={{marginTop:"1em"}}></div>
              
              </DialogActions>
            </Dialog>


            <Dialog
              open={this.state.openUser}
              onClose={this.closeUser}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold">Mon Compte</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeUser}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
              
              {this.state.utilisateurConnect != null && (
                <Table component={Paper}>  
                <TableRow>
                <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Utilisateur</span></TableCell>
                <TableCell><span class="lato-regular" style={{fontSize:"15px"}}>{this.state.utilisateurConnect.utilisateur.email}</span></TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Abonnement</span></TableCell>
                <TableCell><span class="lato-regular" style={{fontSize:"15px"}}>{this.state.utilisateurConnect.utilisateur.abonnment}</span></TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Expiration</span></TableCell>
                <TableCell><span class="lato-regular" style={{fontSize:"15px"}}>{this.state.utilisateurConnect.utilisateur.expiration}</span></TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Session</span></TableCell>
                <TableCell>
                <Button 
                  fullWidth 
                  variant="outlined"
                  onClick={() => this.deco()}
                  startIcon={<ArrowRightIcon />}>
                  <span class="lato-regular" style={{ textTransform:"none", fontSize:"15px"}}>Se déconnecter</span>
                </Button>

                </TableCell>
                </TableRow>
                </Table>  


                
              )}
              
              </DialogContent>
              <Divider/>
              <DialogActions>
              <Grid container spacing={2}>
                      
                <Grid item xs={6}>
                   
                </Grid>
                <Grid item xs={6}>
                  <IconButton
                      aria-label="close"
                      onClick={this.closeUser}
                      style={{marginLeft:"1em", float:"right"}}
                      //onClick={}
                    >
                    <CheckOutlinedIcon style={{color:"black"}} />
                    </IconButton>
                </Grid>
              </Grid>
              
              </DialogActions>
            </Dialog>

            </div>

        )
    }
}