import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import * as Const from "../Constant";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import fileDownload from 'js-file-download'
import axios from 'axios'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogAide from "./DialogAide";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauCombinaisonJeux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      loader: false,
      aide:false
    };
    
  }

  handleDownload = (url, filename) => {
    this.setState({ loader: true})
    axios.get(Const.URL_WS_METHODE_EXPORT + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}
    &selection=${this.state.data.methode.idSelection}
    &conditionCourse=${this.state.data.methode.idCondition}
    &periode=${this.state.data.methode.periode.id}`, {
      responseType: 'blob',
    })
    .then((res) => {
      this.setState({ loader: false})
      fileDownload(res.data, filename)
    })
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  openAide = () => {
    this.setState({ aide: true})
  }

  closeAide = () => {
    this.setState({ aide: false})
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div >
        {this.state.aide && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAide}
    content={<span class="lato-regular">Exportez les résultats et l'historique de la sélection vers un fichier tableur, tel que Microsoft Excel, pour une analyse plus approfondie.</span>}
    />
    )}

      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">Téléchargement (export .csv)</span></TableCell>
            <TableCell>
              
            <IconButton aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.openAide} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Bilan simulation</span></TableCell>
              <TableCell><span>+</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Permanences des jeux</span></TableCell>
              <TableCell><span><CloudDownloadOutlinedIcon style={{cursor:"pointer"}} onClick={() => {this.handleDownload('', 'trotstats_export.xls')}} size="small"/></span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell style={{height:"11px"}}></TableCell>
            </TableRow>
            
        </TableBody>
      </Table>

      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

      </div>
    )
  }
}