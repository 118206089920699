/*
export const URL = "http://localhost:3000/";
export const URL_WS_CRITERES = "http://localhost:8080/criteres/categorie";
export const URL_WS_FILTRES = "http://localhost:8080/filtres";
export const URL_WS_FILTRE = "http://localhost:8080/filtre";
export const URL_WS_SELECTION_SAVE = "http://localhost:8080/selection/save";
export const URL_WS_CONDITION_SAVE = "http://localhost:8080/condition/save";
export const URL_WS_SELECTIONS = "http://localhost:8080/selections";
export const URL_WS_SELECTION = "http://localhost:8080/selection";
export const URL_WS_CONDITIONS = "http://localhost:8080/conditions";
export const URL_WS_CONDITION = "http://localhost:8080/condition";
export const URL_WS_PERIODES = "http://localhost:8080/periodes";
export const URL_WS_CONDITIONS_RECHERCHE = "http://localhost:8080/conditions/recherche";
export const URL_WS_SELECTIONS_RECHERCHE = "http://localhost:8080/selections/recherche";
export const URL_WS_SELECTION_DELETE = "http://localhost:8080/selection/delete";
export const URL_WS_CONDITION_DELETE = "http://localhost:8080/condition/delete";
export const URL_WS_CONDITION_DOSSIER = "http://localhost:8080/condition/dossier/update";
export const URL_WS_SELECTION_DOSSIER = "http://localhost:8080/selection/dossier/update";
export const URL_WS_METHODE_TEST = "http://localhost:8080/methode/exec";
export const URL_WS_MAINTENANCE = "http://localhost:8080/config/maintenance";
export const URL_WS_METHODE_SAVE = "http://localhost:8080/methode/save";
export const URL_WS_METHODE_EXPORT = "http://localhost:8080/methode/export";
export const URL_WS_METHODE_PRONOSTICS = "http://localhost:8080/methode/pronostics";
export const URL_WS_METHODE_DELETE = "http://localhost:8080/methode/pronostics/delete";
export const URL_WS_METHODE_EXEC = "http://localhost:8080/methode/exec/methode";
export const URL_WS_METHODE_ACTIF = "http://localhost:8080/methode/actif";
export const URL_WS_COURSE_JOUR = "http://localhost:8080/courses/jour";
export const URL_WS_FICHE_PARTANT = "http://localhost:8080/fiche";
export const URL_WS_FICHE_COURSE = "http://localhost:8080/fiche/course";
export const URL_WS_FILTRE_SAVE = "http://localhost:8080/filtre/save";
export const URL_WS_DATES = "http://localhost:8080/dates";
export const URL_WS_AUTH = "http://localhost:8080/auth";
export const URL_WS_MEETINGS = "http://localhost:8080/meetings";
export const URL_WS_SYNTHESE = "http://localhost:8080/synthese";
*/



export const URL = "https://trotstats.fr/";
export const URL_WS_CRITERES = "https://dataturf.ddns.net:8443/criteres/categorie";
export const URL_WS_FILTRES = "https://dataturf.ddns.net:8443/filtres";
export const URL_WS_FILTRE = "https://dataturf.ddns.net:8443/filtre";
export const URL_WS_SELECTION_SAVE = "https://dataturf.ddns.net:8443/selection/save";
export const URL_WS_CONDITION_SAVE = "https://dataturf.ddns.net:8443/condition/save";
export const URL_WS_SELECTIONS = "https://dataturf.ddns.net:8443/selections";
export const URL_WS_SELECTION = "https://dataturf.ddns.net:8443/selection";
export const URL_WS_CONDITIONS = "https://dataturf.ddns.net:8443/conditions";
export const URL_WS_CONDITION = "https://dataturf.ddns.net:88443083/condition";
export const URL_WS_PERIODES = "https://dataturf.ddns.net:8443/periodes";
export const URL_WS_CONDITIONS_RECHERCHE = "https://dataturf.ddns.net:8443/conditions/recherche";
export const URL_WS_SELECTIONS_RECHERCHE = "https://dataturf.ddns.net:8443/selections/recherche";
export const URL_WS_SELECTION_DELETE = "https://dataturf.ddns.net:8443/selection/delete";
export const URL_WS_CONDITION_DELETE = "https://dataturf.ddns.net:8443/condition/delete";
export const URL_WS_CONDITION_DOSSIER = "https://dataturf.ddns.net:8443/condition/dossier/update";
export const URL_WS_SELECTION_DOSSIER = "https://dataturf.ddns.net:8443/selection/dossier/update";
export const URL_WS_METHODE_TEST = "https://dataturf.ddns.net:8443/methode/exec";
export const URL_WS_MAINTENANCE = "https://dataturf.ddns.net:8443/config/maintenance";
export const URL_WS_METHODE_SAVE = "https://dataturf.ddns.net:8443/methode/save";
export const URL_WS_METHODE_EXPORT = "https://dataturf.ddns.net:8443/methode/export";
export const URL_WS_METHODE_PRONOSTICS = "https://dataturf.ddns.net:8443/methode/pronostics";
export const URL_WS_METHODE_DELETE = "https://dataturf.ddns.net:8443/methode/pronostics/delete";
export const URL_WS_METHODE_EXEC = "https://dataturf.ddns.net:8443/methode/exec/methode";
export const URL_WS_METHODE_ACTIF = "https://dataturf.ddns.net:8443/methode/actif";
export const URL_WS_COURSE_JOUR = "https://dataturf.ddns.net:8443/courses/jour";
export const URL_WS_FICHE_PARTANT = "https://dataturf.ddns.net:8443/fiche";
export const URL_WS_FICHE_COURSE = "https://dataturf.ddns.net:8443/fiche/course";
export const URL_WS_FILTRE_SAVE = "https://dataturf.ddns.net:8443/filtre/save";
export const URL_WS_DATES = "https://dataturf.ddns.net:8443/dates";
export const URL_WS_AUTH = "https://dataturf.ddns.net:8443/auth";
export const URL_WS_MEETINGS = "https://dataturf.ddns.net:8443/meetings";
export const URL_WS_SYNTHESE = "https://dataturf.ddns.net:8443/synthese";


