import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';

export default class EditeurTableau extends Component {

    constructor(props) {
        super(props);
        this.state = {
          tab: [],
          selections: [],
          descriptions: [],
          openDialogEnregistrer: false,
          recherche: "",
          rechercheFiltre: "",
          selectedIndex: 0,
          selectionFiltres: "",
          openDrawerAide: false,
          sousCategorie: "TROTTEUR",
          selectionNom: "Sélection_" + Date.now(),
          dossier: "Sélections Personnelles",
          openSnackBar: false
        }

        this.tab = [];
    }

    componentWillReceiveProps(props) {
        
    }

    componentDidMount() {
        let o = {};
        o.id = 16;
        o.aide = "Age du trotteur.";
        this.setState({selectedCritere: o});
        this.wsCriteres();

        let idSelectionModifie = localStorage.getItem("idSelectionModifie");

        if(idSelectionModifie != null){
          this.loadSelectionModifie(idSelectionModifie);
          localStorage.removeItem("idSelectionModifie")
        }
    }

    closeDialogEnregistrer = () => {
      this.setState({openDialogEnregistrer: false});
    }

    closeSnackBar = () => {
      this.setState({openSnackBar: false});
    }

    closeDrawerAide = () => {
      this.setState({openDrawerAide: false});
    }

    openDialogEnregistrer = () => {
      this.setState({selectionNom: "Sélection_" + Date.now()});
      this.setState({openDialogEnregistrer: true});
    }

    handleSelect = (event) => {
      this.setState({ dossier: event.target.value});
    }
    
    handleSousCategorie = (event) => {
      
      this.setState({ sousCategorie: event.target.value }, () => {
        this.wsCriteres();
      });
      
    }

  

   

    redirectHome = () => {
      window.location.href = Const.URL + "editeur";
    }

    

    keyPress = (e) => {
      if(e.keyCode == 13){
         this.wsCriteres();
      }
   }

   

  

    handleCritereSelect = (index, obj) => {
      this.setState({ selectedIndex: index });
      this.setState({ selectedCritere: obj });
    }

    handleAdd = (obj) => {
      alert(obj.id + " " +  obj.labelCategorie + "  " +  obj.label)
    }

   

    wsSelectionSave = () => {
      let utilisateur = localStorage.getItem("utilisateur_dtf");
      fetch(
        Const.URL_WS_SELECTION_SAVE + `?utilisateur=${utilisateur}
        &selectionNom=${this.state.selectionNom}
        &selectionDescription=${this.state.selectionDescription}
        &selectionFiltres=${this.state.selectionFiltres}
        &selectionDossier=${this.state.dossier}`,
        { retry: 0, retryDelay: 1000 }
      )
      .then((res) => res.json())
      .then((data) => {
        if(data == true){
          this.setState({openSnackBar: true});
        }else{
          alert("Erreur");
        }
      })
      .catch((error) => {
          console.log("Request failed", error);
      });
      this.closeDialogEnregistrer();
    }

    wsCriteres = () => {    
        fetch(
            Const.URL_WS_CRITERES + `?categorie=CRITERE_PARTANT&sousCategorie=${this.state.sousCategorie}&search=${this.state.recherche}`,
            { retry: 0, retryDelay: 1000 }
        )
        .then((res) => res.json())
        .then((data) => {
            this.setState({ criteres: data})
        })
        .catch((error) => {
            console.log("Request failed", error);
        });
    }



    render() {
        return (
            <div>
               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.5em"}}>- Éditeur de tableau</span> 
                  </div>
                </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{float:"right", margin:"0.5em"}}>
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={() => this.openDialogEnregistrer()}
                  >
                  <CloudUploadIcon style={{color:"black"}} />
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectHome}
                  >
                  <CloseIcon style={{color:"black"}} />
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: '30vw', maxWidth: '30vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white' , overflow:'scroll', overflowX:'hidden' }}>
                <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Liste des critères</span>
                  <FormControl style={{marginTop:"1em"}} fullWidth>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Catégorie</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.sousCategorie}
                    label="Catégorie"
                    defaultValue={"Informations trotteurs"}
                    onChange={this.handleSousCategorie}
                  >
                  <MenuItem value={"TROTTEUR"}><span class="lato-regular" style={{fontSize:"14px"}}>Informations trotteurs</span></MenuItem>
                  
                  <Divider variant="middle"/>

                  <MenuItem value={"TROTTEUR_INDICATEUR"}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs trotteurs</span></MenuItem>
                  <MenuItem value={"DRIVER_INDICATEUR"}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs drivers</span></MenuItem>
                  <MenuItem value={"ENTRAINEUR_INDICATEUR"}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs entraineurs</span></MenuItem>

                  <Divider variant="middle"/>

                  <MenuItem value={"TROTTEUR_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs</span></MenuItem>
                  <MenuItem value={"TROTTEUR_CORDE_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - cordes</span></MenuItem>
                  <MenuItem value={"TROTTEUR_DEFERRAGE_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - déferrages</span></MenuItem>
                  <MenuItem value={"TROTTEUR_DISTANCE_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - distances</span></MenuItem>
                  <MenuItem value={"TROTTEUR_DRIVER_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - drivers</span></MenuItem>
                  <MenuItem value={"TROTTEUR_DRIVER_HIPPODROME_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - drivers - hippodromes</span></MenuItem>
                  <MenuItem value={"TROTTEUR_ENTRAINEUR_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - entraineurs</span></MenuItem>
                  <MenuItem value={"TROTTEUR_HIPPODROME_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - hippodromes</span></MenuItem>
                  <MenuItem value={"TROTTEUR_AUTOSTART_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - autostarts</span></MenuItem>
                  <MenuItem value={"TROTTEUR_QUINTE_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - quintés</span></MenuItem>

                  <Divider variant="middle"/>

                  <MenuItem value={"DRIVER_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers</span></MenuItem>
                  <MenuItem value={"DRIVER_ENTRAINEUR_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers - entraineurs</span></MenuItem>
                  <MenuItem value={"DRIVER_HIPPODROME_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers - hippodromes</span></MenuItem>
                  <MenuItem value={"DRIVER_QUINTE_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers - quintés</span></MenuItem>
                  
                  <Divider variant="middle"/>

                  <MenuItem value={"ENTRAINEUR_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques entraineurs</span></MenuItem>
                  <MenuItem value={"ENTRAINEUR_HIPPODROME_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques entraineurs - hippodromes</span></MenuItem>
                  <MenuItem value={"ENTRAINEUR_QUINTE_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques entraineurs - quintés</span></MenuItem>

                 
                  </Select>
                </FormControl>

                <TextField
                  style={{marginTop:"1em", marginBottom:"1em"}}
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPress}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.recherche}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({recherche: event.target.value});
                  }}
                />

                </ListSubheader>
                {this.state.criteres != null && this.state.criteres.criteres.map((o, index) => (
                  <div style={{display: o.labelCategorie != '' ? 'block' : 'none'}}>
                  <ListItem style={{backgroundColor: index === this.state.selectedIndex ? '#D4D4D4' : 'transparent' }}>                   
                        <ListItemText onClick={() => this.handleCritereSelect(index, o)} style={{cursor:"pointer"}} 
                          primary={<div> <span class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> <ArrowForwardOutlinedIcon onClick={() => this.handleAdd(o)} style={{float:"right", color:"gray"}}/></div>} 
                        />
                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.criteres != null && this.state.criteres.criteres.length == 0 && (
                  <Alert style={{margin:"1em"}} severity="warning"><span style={{fontSize:"14px"}} class="lato-regular">Aucun résultat.</span></Alert>
                )}

                </List>
            </Box>

            <Box sx={{  width: '70vw', maxWidth: '70vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'red', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: '70vw', maxWidth: '70vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white', overflow:'scroll', overflowX:'hidden', borderBottomWidth:"10px", borderBottomStyle:"inset" }}>
                <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Tableau d'information</span>
                </ListSubheader>
                 

                <Alert
                    severity="info"
                    sx={{ width: '100%' }}
                    >
                    <span class="lato-regular" style={{fontSize:"14px"}}>
                    Créez votre tableau d'information en ajoutant les critères souhaités.   
                    </span>
                 </Alert>
               
                 {/* ICI LES COLONNES DU TABLEAU CHOISIES */}

                </List>
                    
                </Box>

                
              </div>
                
            </Box>

            <Dialog
              open={this.state.openDialogEnregistrer}
              onClose={this.closeDialogEnregistrer}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold" style={{fontSize:"18px"}}>Enregistrer</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialogEnregistrer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
                
                <TextField
                  style={{marginTop:"0.5em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  defaultValue={this.state.selectionNom}
                  label={<span class="lato-regular">Nom</span>}
                  InputProps={{
                    className: "lato-regular",
                  }}
                  value={this.state.selectionNom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({selectionNom: event.target.value});
                  }}
                />

                <FormControl fullWidth style={{marginTop:"1.5em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Sélections Personnelles"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Sélections Personnelles"}><span class="lato-regular" style={{fontSize:"14px"}}>Sélections personnelles</span></MenuItem>
                  <MenuItem value={"Brouillon"}><span class="lato-regular" style={{fontSize:"14px"}}>Brouillon</span></MenuItem>
                  
                  </Select>
                </FormControl>

              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.wsSelectionSave}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

                
               
              </DialogActions>
            </Dialog>

            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              severity="success"
              onClose={this.closeSnackBar}
              message="Enregistrer"
            >
               <Alert
                  onClose={this.closeSnackBar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                <span class="lato-regular" style={{fontSize:"14px"}}>Sélection enregistrée</span>
              </Alert>
            </Snackbar>

            <Drawer style={{width:"500px"}} open={this.state.openDrawerAide}  anchor={"right"} onClose={this.closeDrawerAide}>
            <Box sx={{ width: 600 }} role="presentation">
              <Alert
                  severity="info"
                  variant="filled"
                  style={{margin:"1em"}}
                >
                <span class="lato-regular" style={{fontSize:"14px"}}>{this.state.descriptionAide}</span>
              </Alert>
              </Box>
            </Drawer>


            </div>
            </div>

        )
    }
}