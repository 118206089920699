import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogAide from "./DialogAide";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauBacktestJeux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      aide:false
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  openAide = () => {
    this.setState({ aide: true})
  }

  closeAide = () => {
    this.setState({ aide: false})
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function format(data){
      return data.toLocaleString('fr-FR');
    }

    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
        )
      
    }


    return(

      <div >
        {this.state.aide && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAide}
    content={<span class="lato-regular">Ce tableau résume les données clés sur les chevaux joués, avec une moyenne par course et des statistiques sur les courses ayant au moins un cheval gagnant ou placé. Il offre une vue globale des performances et des choix stratégiques.</span>}
    />
    )}
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">#</span></TableCell>
            <TableCell>
            <IconButton aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.openAide} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Nombre de chevaux joués :</span></TableCell>
              <TableCell><span class="lato-regular">{format(this.state.data.analyse.nombreChevauxRetenu)}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Nombre moyen de chevaux par course :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.nombreMoyenChevauxRetenuParCourse}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Nombre de courses avec un cheval gagnant :</span></TableCell>
              <TableCell>
                <div style={{display:"flex"}}>
                  <div style={{width:'60px'}}>
                  <span class="lato-regular">{format(this.state.data.analyse.compteurCourseAvecChevalGagnant)}</span>
                  </div>
                  <div style={{marginLeft:"0.5em"}}>
                  {reussite(this.state.data.analyse.reussiteCourseAvecChevalGagnant)}
                  </div>
                </div>
                 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Nombre de courses avec au moins un cheval placé :</span></TableCell>
              <TableCell>
              <div style={{display:"flex"}}>
                  <div style={{width:'60px'}}>
                  <span class="lato-regular">{format(this.state.data.analyse.compteurCourseAvecChevalPlace)}</span>
                  </div>
                  <div style={{marginLeft:"0.5em"}}>
                  {reussite(this.state.data.analyse.reussiteCourseAvecChevalPlace)}
                  </div>
                </div>
              </TableCell>
            </TableRow>


        </TableBody>
      </Table>
      </div>
    )
  }
}