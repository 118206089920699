import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';

export default class ChoixPeriode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      periodes:null,
      periode: null,
      aidePeriode:false,
      aideOption:false,
      calendarActif: true,
      dateDebut: dayjs().subtract(1, 'day'),
      dateFin: dayjs().subtract(1, 'day')
    };

    this.dateDebut = dayjs().subtract(1, 'day');
    this.dateFin = dayjs().subtract(1, 'day');
    
  }

  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }

  componentDidMount() {
    this.callPeriode();
  }

  callPeriode = () => {
    
    fetch(
      Const.URL_WS_PERIODES + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&pays=FRANCE`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({periodes:data.periodes});
        this.setState({ periode: data.periodes[0] }, () => {
          this.props.handlePeriode(this.state.periode);
        });
        
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  }

  handlePeriode = (event) => {
    
    this.setState({ periode: event.target.value});

    if(event.target.value.label == "Personnalisée"){
      this.setState({calendarActif: false});
    }else{
      this.setState({calendarActif: true});
    }

    this.props.handlePeriode(event.target.value);
    
  }

  aidePeriode = () => {
    this.setState({ aidePeriode: true})
  }

  closeAidePeriode = () => {
    this.setState({ aidePeriode: false})
  }

  selectDateDebut = (event) => {
    this.setState({ dateDebut: event})
    this.props.handlePeriodeOptionDebut(event);
  }

  selectDateFin = (event) => {
    this.setState({ dateFin: event})
    this.props.handlePeriodeOptionFin(event);
  }


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>
         {this.state.aidePeriode && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAidePeriode}
    content={<span class="lato-regular">
      <b>Comment utiliser le bloc Période ?</b>
      <br/><br/>
      
      Choisissez la période sur laquelle la performance de vos pronostics sera évaluée. Par exemple, une période couvrant les trois derniers mois vous permet d’identifier les tendances récentes.
      </span>}
    />
    )}



      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              P
          </Avatar>
          }
          action={
          <div>
         
          <IconButton onClick={this.redirectEditeurSelection} aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.aidePeriode} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Période</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Critère sur la période.</span>}
      />
      <CardContent>
     
      <FormControl fullWidth>
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={this.state.periode}
          onChange={this.handlePeriode}
        >
         {this.state.periodes != null && this.state.periodes.map((name) => (
            <MenuItem
              key={name.id}
              value={name}
            >
             <span class="lato-regular" style={{fontSize:"14px"}}>{name.label}</span>
            </MenuItem>
          ))}
        </Select>
       

        <div style={{marginTop:"2em", marginBottom:"1.5em"}} >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          disabled={this.state.calendarActif}
          label="Date début"
          minDate= {dayjs(new Date(2023, 0, 1))}
          maxDate= {dayjs().subtract(1, 'day')}
          value={this.state.dateDebut}
          onChange={(newValue) => this.selectDateDebut(newValue)}
          slotProps={{ textField: { fullWidth: true, size:"small" } }}
        />
      </LocalizationProvider>
      </div>
      <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          disabled={this.state.calendarActif}
          minDate= {dayjs(new Date(2023, 0, 1))}
          maxDate= {dayjs().subtract(1, 'day')}
          label="Date fin"
          value={this.state.dateFin}
          onChange={(newValue) => this.selectDateFin(newValue)}
          slotProps={{ textField: { fullWidth: true, size:"small" } }}
        />
      </LocalizationProvider>
      </div>

      </FormControl>
      
    
    
      </CardContent>
      </Card>


      </div>


    )
  }
}