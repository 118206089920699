import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Paper from '@mui/material/Paper';

import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";

export default class ChoixCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      conditions:null,
      condition: null,
      openDrawerCondition: false,
      dossier: "Tout les dossiers",
      nouveauDossier: "Conditions Personnelles",
      recherche: "",
      selectionsRecherche:null,
      openSnackBar: false,
      openDialogChangeDossier: false,
      aideCondition: false
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }
  closeDialogChangeDossier = () => {
    this.setState({openDialogChangeDossier: false});
  }

  componentDidMount() {   
    this.callCondition();
  }

  selectCondition = (condition) => {
    this.setState({ condition:condition }, () => {
      this.props.handleCondition(condition);    
      this.closeDrawerCondition();
    });
    
  }

  closeSnackBar = () => {
    this.setState({openSnackBar: false});
  }

  keyPress = (e) => {
    if(e.keyCode == 13){
       this.callConditionRecherche();
    }
  }

  delete = (condition) => {
    fetch(
      Const.URL_WS_CONDITION_DELETE + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&id=${condition.id}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        if(data == false){
          this.setState({severity:"warning"});
          this.setState({message:"Suppression impossible, cette condition est utilisée dans un pronostics."});
          this.setState({openSnackBar:true});
        }else{
          this.setState({severity:"success"});
          this.setState({message:"Suppression validée."});
          this.setState({openSnackBar:true});
          this.callConditionRecherche();
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  }

  handleNouveauDossier = (event) => {
    this.setState({ nouveauDossier: event.target.value});
  }

  wsChangeDossier = () => {
    fetch(
      Const.URL_WS_CONDITION_DOSSIER + `?dossier=${this.state.nouveauDossier}&condition=${this.state.condition.id}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
      this.closeDialogChangeDossier();
      this.callConditionRecherche();
  }

  callConditionRecherche = () => {
    
    fetch(
      Const.URL_WS_CONDITIONS_RECHERCHE + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&dossier=${this.state.dossier}&search=${this.state.recherche}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({conditions:data.conditions});

        this.setState({ condition: data.conditions[0] }, () => {
          this.props.handleCondition(this.state.condition);
        });
        
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  }

  
  selectDossier = (condition) => {
    this.setState({ condition:condition });
    this.setState({openDialogChangeDossier: true});
  }

  callCondition = () => {
    
    fetch(
      Const.URL_WS_CONDITIONS + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&pays=FRANCE`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({conditions:data.conditions});
        this.setState({ condition: data.conditions[0] }, () => {
          this.props.handleCondition(this.state.condition);
        });
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  }

  handleDossier = (event) => {
    this.setState({ dossier: event.target.value }, () => {
      this.callConditionRecherche();
    });
  }

  handleSelect = (event) => {
    this.setState({ condition: event.target.value});
    this.props.handleCondition(event.target.value);
  }

openCreateCondition = () => {
  this.props.openDialogCreateCondition();
}

modifyAndRedirectEditeurCondition = () => {
  localStorage.setItem("idConditionModifie", this.state.condition.id);
  window.location.href = Const.URL + "editeur/condition";
}

redirectEditeurCondition = () => {
  window.location.href = Const.URL + "editeur/condition";
}

closeDrawerCondition = () => {
  this.setState({ openDrawerCondition: false});
}

openDrawerCondition = () => {
  this.setState({ openDrawerCondition: true});
}

closeAideCondition = () => {
  this.setState({ aideCondition: false})
}

aideCondition = () => {
  this.setState({ aideCondition: true})
}

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>
 {this.state.aideCondition && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAideCondition}
    content={<span class="lato-regular">
      <b>Comment utiliser le bloc Condition ?</b>
      <br/><br/>
      <b>
        <SettingsOutlinedIcon/> 
      </b>
      <br/><br/>
      Cette fonctionnalité vous permet d’administrer vos conditions de course déjà créées.
      <br/><br/>
      <b>
        <AutoFixHighOutlinedIcon/> 
      </b>
      <br/><br/>
      Utilisez la fonctionnalité de copie pour dupliquer une condition existante. Cela vous permet de créer rapidement une nouvelle condition de course basée sur les paramètres de l’originale.
      <br/><br/>
      <b>
        <AddCircleOutlineOutlinedIcon/> 
      </b>
      <br/><br/>
      Utilisez cette option pour générer une nouvelle condition de course adaptée à vos critères spécifiques. Vous pourrez ainsi personnaliser votre analyse dès le départ.
    
      </span>}
    />
    )}

      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              C
          </Avatar>
          }
          action={
          <div>
          <IconButton aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.aideCondition} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>

          <IconButton onClick={this.openDrawerCondition} aria-label="settings">
            <Tooltip title={<span class="lato-regular">Gestion des conditions</span>}>
              <SettingsOutlinedIcon style={{color:"black"}}/>
            </Tooltip>
          </IconButton>

          <IconButton onClick={this.modifyAndRedirectEditeurCondition} aria-label="settings">
              <Tooltip title={<span class="lato-regular">Copier la condition</span>}>
                <AutoFixHighOutlinedIcon style={{color:"black"}}/>
              </Tooltip>
          </IconButton>

          <IconButton onClick={this.redirectEditeurCondition} aria-label="settings">
              <Tooltip title={<span class="lato-regular">Créer une nouvelle condition</span>}>
                <AddCircleOutlineOutlinedIcon style={{color:"black"}}/>
              </Tooltip>
          </IconButton>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Condition</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Critères sur les courses.</span>}
      />
      <CardContent>
     
      <FormControl fullWidth>
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.condition}
          onChange={this.handleSelect}
          size="small"
        >
          {this.state.conditions != null && this.state.conditions.map((name) => (
            <MenuItem
              key={name.id}
              value={name}
            >
               <span class="lato-regular"  style={{fontSize:"14px"}}>{name.label}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <div style={{marginTop:"0.5em"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <span class="lato-bold" style={{fontSize:"14px"}}>Filtres</span>
        </AccordionSummary>
        <AccordionDetails>
        <Table component={Paper}>
        
         
          {this.state.condition != null && this.state.condition.description.split(";").map((item, index) => (
            <TableRow>
                <TableCell align="left"><span class="lato-regular" style={{fontSize:"14px"}}>{item.split(":")[0]}</span></TableCell>
                <TableCell><span class="lato-regular" style={{fontSize:"14px"}}>{item.split(":")[1]}</span></TableCell>
              </TableRow>
          ))}
          
           
          </Table>
        </AccordionDetails>
      </Accordion>
      </div>
    
      </CardContent>
      </Card>

      <Drawer style={{width:"500px"}} open={this.state.openDrawerCondition}  anchor={"left"} onClose={this.closeDrawerCondition}>
            <Box sx={{ width: '30vw', maxWidth: '30vw', height:'100vh', maxHeight:'100vh', bgcolor: 'white' , overflow:'scroll', overflowX:'hidden' }}>
                <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Liste des conditions</span>
                  <FormControl style={{marginTop:"1em"}} fullWidth>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Tout les dossiers"}
                    onChange={this.handleDossier}
                  >
                  <MenuItem value={"Tout les dossiers"}><span class="lato-regular" style={{fontSize:"14px"}}>Tout les dossiers</span></MenuItem>
                  <MenuItem value={"Conditions Personnelles"}><span class="lato-regular" style={{fontSize:"14px"}}>Conditions personnelles</span></MenuItem>
                  <MenuItem value={"Conditions Trotstats"}><span class="lato-regular" style={{fontSize:"14px"}}>Conditions Trotstats</span></MenuItem>
                  <MenuItem value={"Brouillon"}><span class="lato-regular" style={{fontSize:"14px"}}>Brouillon</span></MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  style={{marginTop:"1em", marginBottom:"1em"}}
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPress}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.recherche}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({recherche: event.target.value});
                  }}
                />

                </ListSubheader>

                {this.state.conditions != null && this.state.conditions.map((o) => (
                  <div>
                    <ListItem>                   
                    <Grid container spacing={2}>
                      
                      <Grid item xs={9}>
                        <div>
                          <span  class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> 
                        </div>
                        <div>
                          <span style={{fontSize:"13px"}}  class="lato-regular-italic">{o.categorie}</span> 
                        </div>
                      </Grid>
                      <Grid item xs={1}>
                        <DriveFolderUploadOutlinedIcon style={{cursor:"pointer", display: o.categorie == 'Conditions Trotstats' ? "none" : "block"}} onClick={() => this.selectDossier(o)}/>
                      </Grid>
                      <Grid item xs={1}>
                        <DeleteOutlineOutlinedIcon style={{cursor:"pointer", display: o.categorie == 'Conditions Trotstats' ? "none" : "block"}} onClick={() => this.delete(o)}/>
                      </Grid>
                      <Grid item xs={1}>
                        <PlayCircleFilledWhiteOutlinedIcon style={{cursor:"pointer"}} onClick={() => this.selectCondition(o)}/>
                      </Grid>
                      
                    </Grid>
                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.conditions != null && this.state.conditions.length == 0 && (
                  <Alert style={{margin:"1em"}} severity="info"><span style={{fontSize:"14px"}} class="lato-regular">Aucune condition.</span></Alert>
                )}

                </List>
            </Box>
            </Drawer>

            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              severity="success"
              onClose={this.closeSnackBar}
              message=""
            >
               <Alert
                  onClose={this.closeSnackBar}
                  severity={this.state.severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                <span class="lato-regular" style={{fontSize:"14px"}}>{this.state.message}</span>
              </Alert>
            </Snackbar>

            <Dialog
              open={this.state.openDialogChangeDossier}
              onClose={this.closeDialogChangeDossier}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold" style={{fontSize:"18px"}}>Déplacer vers ...</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialogChangeDossier}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
               
                <FormControl fullWidth style={{marginTop:"1.5em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.nouveauDossier}
                    label="Dossier"
                    defaultValue={"Conditions Personnelles"}
                    onChange={this.handleNouveauDossier}
                  >
                  <MenuItem value={"Conditions Personnelles"}><span class="lato-regular" style={{fontSize:"14px"}}>Conditions personnelles</span></MenuItem>
                  <MenuItem value={"Brouillon"}><span class="lato-regular" style={{fontSize:"14px"}}>Brouillon</span></MenuItem>
                  </Select>
                </FormControl>

              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.wsChangeDossier}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

                
               
              </DialogActions>
            </Dialog>

      </div>

    )
  }
}